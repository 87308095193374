<template>
  <div>
    <div class="container">
      <!-- Application Dashboard -->
      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="card card-default" v-if="clientResource">
            <div class="card-body bg-white">
              <div class="row">
                <div class="col">
                  <a
                    :href="
                      '/clients/' + clientResource.client.id + '#resources-tab'
                    "
                    class="btn btn-outline-primary"
                    ><i class="fa fa-arrow-left me-2"></i>Back to Client
                    Resources</a
                  >
                </div>
                <div class="col-auto my-auto ms-auto">
                  <button
                    @click="revokeResource"
                    class="btn btn-outline-danger"
                  >
                    <i class="fa fa-trash me-2"></i>Revoke Client Resource
                  </button>
                </div>
              </div>
              <div class="row mt-3 mb-3">
                <div class="col">
                  <div class="card text-center border-0 bg-light">
                    <div class="card-body">
                      <p class="mb-1 fw-bold text-uppercase mt-2 mb-0">
                        Resource
                      </p>
                      <p class="text-capitalize">
                        {{ clientResource.resource.title }}
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col">
                  <div class="card text-center border-0 bg-light">
                    <div class="card-body">
                      <p class="mb-1 fw-bold text-uppercase mt-2 mb-0">
                        Client
                      </p>
                      <p>
                        {{
                          clientResource.client.first_name +
                          " " +
                          clientResource.client.last_name
                        }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card text-center border-0 bg-light">
                    <div class="card-body">
                      <p class="mb-1 fw-bold text-uppercase mt-2 mb-0">
                        Client Resource Status
                      </p>
                      <p
                        v-if="clientResource.read_by_client"
                        class="text-success"
                      >
                        READ
                      </p>
                      <p v-else class="text-danger">UNREAD</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col">
                  <h5 class="">
                    Resource #{{ clientResource.resource.id }} ({{
                      clientResource.resource.title
                    }})
                  </h5>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-3 mb-3">
                  <div class="card border-0">
                    <div class="card-body">
                      <div class="row">
                        <div class="col text-center">
                          <h6 class="mb-0 text-primary wrap-ellipses">
                            {{ clientResource.resource.title }}
                          </h6>
                          <p
                            v-if="
                              clientResource.resource.model === 'UrlResource'
                            "
                            class="mb-0 bg-light mt-3 p-2"
                          >
                            <i class="fas fa-link" style="font-size: 36px"></i>
                          </p>
                          <p
                            v-if="
                              clientResource.resource.model === 'FileResource'
                            "
                            class="mb-0 bg-light mt-3 p-2"
                          >
                            <i class="fas fa-file" style="font-size: 36px"></i>
                          </p>
                          <button @click="viewResource"
                            class="btn btn-sm btn-outline-primary mt-3 w-100"
                            ><i class="fa fa-eye me-2"></i>View</button
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col">
                  <h5 class="mb-3">
                    Comments ({{ clientResource.comments.length }})
                  </h5>
                </div>
              </div>
              <div v-for="comment in clientResource.comments">
                <div class="row mt-2">
                  <div class="col">
                    <div
                      class="resource-comment mb-2 row"
                      :class="
                        comment.commenter_model === 'User'
                          ? 'practitioner-comment'
                          : 'client-comment'
                      "
                    >
                      <div
                        v-if="comment.commenter_model === 'User'"
                        class="comment-avatar col-md-1 col-sm-2 text-center pe-1 me-3"
                      >
                        <img
                          class="mx-auto rounded-circle img-fluid"
                          :src="user.profile_pic_url"
                          alt="avatar"
                        />
                      </div>
                      <div class="comment-content col-md-8 col-sm-10">
                        <h6 class="small comment-meta">
                          {{ comment.created_at | prettyTime }}
                        </h6>
                        <div
                          class="comment-body"
                          v-html="comment.comment_text"
                        ></div>
                      </div>
                      <div
                        v-if="comment.commenter_model === 'Client'"
                        class="comment-avatar col-md-1 col-sm-2 text-center pe-1 ms-3"
                      >
                        <div class="avatar-circle mt-2">
                          <span class="initials-avatar">{{
                            clientResource.client.name | initials
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
              </div>
              <div
                class="row mt-4 mb-4"
                v-if="clientResource.comments.length === 0"
              >
                <div class="col">
                  <p class="font-italic text-center">
                    No comments yet. Add one below.
                  </p>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col">
                  <h5 class="">Add a Comment</h5>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col">
                  <text-editor v-model="comment"></text-editor>
                  <button class="btn btn-outline-primary" @click="addComment">
                    Add Comment
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TextEditor } from "@jaythegeek/crmtoolkit";
export default {
  props: ["clientResource"],
  data() {
    return {
      clientResource: null,
      comment: '',
    };
  },
  methods: {
    fetchClientResource() {
      this.$axios
              .get(process.env.VUE_APP_API_URL + "/clients/fetch-client-resource/" + this.$route.params.resource,)
              .then(({ data }) => {
                this.clientResource = data;
              });
    },
    addComment() {
      let _this = this;
      this.$axios
        .post(
          process.env.VUE_APP_API_URL + "/resources/add-practitioner-comment",
          {
            client_resource_id: this.clientResource.id,
            comment: this.comment,
          }
        )
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          _this.comment = "";
          this.fetchClientResource();
        });
    },
    revokeResource() {
      var confirmed = confirm(
        "Are you sure you wish to revoke this resource from the client?"
      );
      if (confirmed) {
        this.$axios
          .post(process.env.VUE_APP_API_URL + "/resources/revoke-share", {
            client_resource_id: this.clientResource.id,
          })
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
            this.$router.push("/clients/" + this.clientResource.client_id + "#resources-tab");
          });
      }
    },
    viewResource() {
      this.$router.push('/resources/' + this.clientResource.resource.id);
    }
  },
  filters: {
    initials(str) {
      let arr = str.split(" ");
      const first = arr[0].charAt(0).toUpperCase();
      const last = arr[arr.length - 1].charAt(0).toUpperCase();
      return first + last;
    },
    prettyTime(date) {
      return moment(date).fromNow();
    },
  },
  mounted() {
    this.fetchClientResource();
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    }
  },
  components: { TextEditor },
};
</script>

<style>
</style>
